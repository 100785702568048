import { Component, Input, OnInit } from '@angular/core';
import { DayOfWeek } from 'src/app/model/day-of-week.model';
import { RestSalasDataSource } from 'src/app/model/rest.salas.datasource';

@Component({
    selector: 'app-sala-semanal',
    templateUrl: './sala-semanal.component.html',
    styleUrls: ['./sala-semanal.component.scss'],
})
export class SalaSemanalComponent {

    @Input() salaId: string;
    @Input() totalVagasSala: number;
    @Input() reservas: any;

    public DayOfWeekEnum = DayOfWeek;
    public horarios = Array.from({ length: 13 }, (v, k) => k + 8);

    constructor(private salasRepository: RestSalasDataSource) { }

    public getReservasFromServer() {
        this.salasRepository.getReservasSala(this.salaId).subscribe(data => {
            this.reservas = data;
        });
    }


    obterReserva(chaveReserva: string) {
        if (this.reservas && chaveReserva in this.reservas)
            return this.reservas[chaveReserva];
    }
}