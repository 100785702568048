import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { Evolucao, StatusEvolucao } from "./evolucao";
import { Paciente } from "./paciente.model";
import { PagedResponse } from "./paged-response.model";
import { ProjetoTerapeutico } from "./projeto-terapeutico.model";



@Injectable()
export class RestPacientesDataSource {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.ApiUrl
    }

    savePaciente(paciente: Paciente): Observable<Object> {
        return this.http.post(this.baseUrl + "/pacientes", paciente);
    }

    addEvolucao(pacienteId: string, form: FormData): Observable<Object> {
        return this.http.post(this.baseUrl + `/pacientes/${pacienteId}/evolucoes`, form);
    }

    updateEvolucao(pacienteId: string, evolucaoId: string, form: FormData): Observable<Object> {
        return this.http.put(this.baseUrl + `/pacientes/${pacienteId}/evolucoes/${evolucaoId}`, form);
    }

    getListPacientes(): Observable<Paciente[]> {
        return this.http.get<Paciente[]>(this.baseUrl + "/pacientes");
    }

    getListPacientesLightVersion(): Observable<Paciente[]> {
        return this.http.get<Paciente[]>(this.baseUrl + "/pacientes/light");
    }

    getPacienteById(id: string): Observable<Paciente> {
        return this.http.get<Paciente>(this.baseUrl + `/pacientes/${id}`);
    }

    getEvolucaoById(pacienteId: string, evolucaoId: string): Observable<Evolucao> {
        return this.http.get<Evolucao>(this.baseUrl + `/pacientes/${pacienteId}/evolucoes/${evolucaoId}`);
    }

    getProntuarioOfPaciente(id: string, page: number): Observable<PagedResponse<Evolucao>> {
        return this.http.get<PagedResponse<Evolucao>>(this.baseUrl + `/pacientes/${id}/prontuario?page=${page}`);
    }

    getProjetoTerapeuticoByPacienteId(pacienteId: string): Observable<ProjetoTerapeutico> {
        return this.http.get<ProjetoTerapeutico>(this.baseUrl + `/pacientes/${pacienteId}/projeto-terapeutico`);
    }

    getUltimosAtendimentos(pacienteId: string): Observable<any> {
        return this.http.get<any>(this.baseUrl + `/pacientes/${pacienteId}/atendimentos?limit=3`);
    }
}
