import { Component, Input, OnInit } from '@angular/core';
import { DateClickArg } from '@fullcalendar/interaction';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Paciente } from '../model/paciente.model';
import { RestAtendimentosDataSource } from '../model/rest.atendimentos.datasource';
import { RestPacientesDataSource } from '../model/rest.pacientes.datasource';

@Component({
	selector: 'ngbd-modal-confirm',
	templateUrl: 'add-atendimento.component.html',
	styleUrls: ['./add-atendimento.component.scss']
})
export class AddAtendimentoModalComponent implements OnInit {
	public pacientes: Paciente[];
	public addAtendimentoForm: any = {};
	public loading: boolean = false;

	@Input() event: DateClickArg;
	@Input() statusList: any[];

	constructor(
		public modal: NgbActiveModal,
		private pacientesRepository: RestPacientesDataSource,
		private atendimentoRepository: RestAtendimentosDataSource,
		private toastr: ToastrService) { }

	ngOnInit(): void {
		if (this.event.view.type == 'timeGridDay' || this.event.view.type == 'timeGridWeek') {
			this.addAtendimentoForm.initTime = moment(this.event.date).format("HH:mm");
			this.addAtendimentoForm.endTime = moment(this.event.date).add(1, 'hour').format("HH:mm");
		}

		this.pacientesRepository.getListPacientes().subscribe(
			data => this.pacientes = data,
			error => console.error(error)
		);
	}

	salvarAgendamento() {
		if (!this.addAtendimentoForm.statusId) {
			this.toastr.error('Informe o status do atendimento');
			return;
		}

		this.loading = true;

		this.addAtendimentoForm.dataAtendimento = this.event.date;

		this.atendimentoRepository.adicionarAtendimentoManual(this.addAtendimentoForm).subscribe(
			() => window.location.reload(),
			error => {
				console.error(error);
				this.loading = false;
				this.modal.close();
			}
		);
	}
}