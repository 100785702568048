<div class="row m-3 mt-5">
    <div class="col-lg-12">

        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th>Segunda</th>
                        <th>Terça</th>
                        <th>Quarta</th>
                        <th>Quinta</th>
                        <th>Sexta</th>
                        <th>Sábado</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let horario of horarios">
                        <td class="text-center align-middle">{{horario | number: '2.'}}:00</td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Monday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Monday')">
                            </app-add-horario>
                        </td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Tuesday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Tuesday')">
                            </app-add-horario>
                        </td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Wednesday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Wednesday')">
                            </app-add-horario>
                        </td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Thursday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Thursday')">
                            </app-add-horario>
                        </td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Friday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Friday')">
                            </app-add-horario>
                        </td>
                        <td class="text-center">
                            <app-add-horario [diaDaSemana]="DayOfWeekEnum.Saturday"
                                             [horario]="horario"
                                             [salaId]="salaId"
                                             [totalVagasSala]="totalVagasSala"
                                             (updateReservas)="getReservasFromServer()"
                                             [itensHorarios]="obterReserva(horario+'-Saturday')">
                            </app-add-horario>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>