import { NgModule } from '@angular/core';
import { IsMobileDirective } from './is-mobile.directive';
import { ArraySortPipe } from './orderby-pipe';


@NgModule({
    imports: [],
    declarations: [IsMobileDirective, ArraySortPipe],
    exports: [IsMobileDirective, ArraySortPipe]
})
export class DirectivesModule { }