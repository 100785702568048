import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Faturamento } from "./faturamento.model";

@Injectable()
export class RestFaturamentoDataSource {

    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.ApiUrl
    }

    //ARRUMAR TIPAGEM
    obterFaturamentos(periodoInicial: Date, periodoFinal: Date): Observable<any> {
        return this.http.get(this.baseUrl + `/faturamentos?periodoInicial=${this.formatDate(periodoInicial)}&periodoFinal=${this.formatDate(periodoFinal)}`);
    }

    criarFaturamento(faturamento: Faturamento): Observable<any> {
        return this.http.post(this.baseUrl + `/faturamentos`, faturamento);
    }

    criarFaturamentoTodosPacientes(dataInicial: Date, dataFinal: Date): Observable<any> {
        return this.http.post(this.baseUrl + `/faturamentos/todos`, { dataInicial, dataFinal });
    }

    obterFaturamentoPorId(faturamentoId: string): Observable<Faturamento> {
        return this.http.get<Faturamento>(this.baseUrl + `/faturamentos/${faturamentoId}`);
    }


    private formatDate(data: Date): string {
        const offset = data.getTimezoneOffset()
        data = new Date(data.getTime() - (offset * 60 * 1000))
        return data.toISOString().split('T')[0]
    }
}
