<div class="row clearfix m-3">
	<full-calendar [options]="calendarOptions"></full-calendar>
</div>

<ng-template #popoverTmpl let-event="event">
	Paciente: <a href="pacientes/{{event._def.extendedProps.pacienteId}}">{{event._def.extendedProps.pacienteNome}}</a><br />
	Terapeuta ocupacional: {{event._def.extendedProps.terapeutaNome}}<br />
	Status:
	<button class="btn btn-secondary dropdown-toggle btn-xsm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
		{{event._def.extendedProps.status.description}}
	</button>
	<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
		<li *ngFor="let statusItem of statusList | sort:'description'"><a class="dropdown-item" [class.active]="event._def.extendedProps.status.id === statusItem.id" href="#" (click)="changeEventStatus(event, statusItem.id)">{{statusItem.description}}</a></li>
	</ul>
	<div class="d-grid gap-2 mt-2" *ngIf="event._def.extendedProps.atendimentoId">
		<button type="button" class="btn btn-danger btn-xsm" (click)="removerAtendimento(event._def.extendedProps.pacienteId, event._def.extendedProps.atendimentoId)">Remover Atendimento</button>
	</div>
</ng-template>