import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
    public email: string;
    public password: string;
    public errorMessage: string;
    public rememberMe: boolean = false;
    public loading: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router) { }

    login(form: NgForm): void {
        if (form.invalid) {
            return;
        }

        this.loading = true;
        this.errorMessage = "";

        this.authenticationService.login(this.email, this.password, this.rememberMe)
            .subscribe(
                () => {
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                    this.router.navigateByUrl(returnUrl);
                },
                (response: HttpErrorResponse) => {
                    if (response.statusText == 'Unknown Error' || response.status == HttpStatusCode.InternalServerError)
                        this.errorMessage = 'Ocorreu uma falha, informe ao administrador!'
                    else
                        this.errorMessage = response.error;

                    this.loading = false;
                }
            );
    }

    ngOnInit(): void {

    }
}
