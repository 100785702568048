import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
      <div class="fc-content" [ngbPopover]="template" [autoClose]="'outside'" container="body" triggers="manual" [popoverTitle]="title">
        <ng-content></ng-content>
      </div>
    `,
})
export class PopoverWrapperComponent {
    template: TemplateRef<any>;
    title: string;

    @ViewChild(NgbPopover, { static: true }) popover: NgbPopover;
}