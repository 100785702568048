import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//Home
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
//Salas
import { ListagemSalasComponent } from './salas/listagem-salas/listagem-salas.component';
import { AuthGuard } from './_helpers/auth.guard';





const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'pacientes',
		loadChildren: () => import('./pacientes/pacientes.module').then(m => m.PacientesModule)
	},
	{
		path: 'salas',
		loadChildren: () => import('./salas/salas.module').then(m => m.SalasModule)
	},
	{
		path: 'agendamentos',
		loadChildren: () => import('./agendamentos/agendamentos.module').then(m => m.AgendamentosModule)
	},
	{
		path: 'financeiro',
		loadChildren: () => import('./financeiro/financeiro.module').then(m => m.FinanceiroModule)
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{ path: '**', redirectTo: '' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
