import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "../_helpers/auth.guard";
import { ListagemSalasComponent } from './listagem-salas/listagem-salas.component';



const routes: Routes = [
	{
		path: '',
		component: ListagemSalasComponent,
		canActivate: [AuthGuard],
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SalasRoutingModule { }