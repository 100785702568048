import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { RestPacientesDataSource } from "./rest.pacientes.datasource";
import { RestTerapeutasDataSource } from "./rest.terapeutas.datasource";
import { RestAtendimentosDataSource } from "./rest.atendimentos.datasource";
import { RestSalasDataSource } from "./rest.salas.datasource";
import { RestFaturamentoDataSource } from "./rest.faturamento.datasource";

@NgModule({
    imports: [HttpClientModule],
    providers: [
        RestPacientesDataSource,
        RestTerapeutasDataSource,
        RestAtendimentosDataSource,
        RestSalasDataSource,
        RestFaturamentoDataSource
    ]
})
export class ModelModule { }
