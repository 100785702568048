import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { Sala } from "./sala.model";
import { DayOfWeek } from "./day-of-week.model";

@Injectable()
export class RestSalasDataSource {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.ApiUrl
    }

    getListSalas(): Observable<Sala[]> {
        return this.http.get<Sala[]>(this.baseUrl + "/salas");
    }

    getReservasSala(salaId: string): Observable<Sala[]> {
        return this.http.get<any>(`${this.baseUrl}/salas/${salaId}/reservas`);
    }

    reservarHorario(salaId: string, horario: number, diaDaSemana: DayOfWeek, pacienteId: string, terapeutaId: string | null = null) {
        return this.http.post(`${this.baseUrl}/salas/${salaId}/reservas/`, { horario, diaDaSemana, pacienteId, terapeutaId });
    }

    removerReserva(salaId: string) {
        return this.http.delete(`${this.baseUrl}/salas/reservas/${salaId}`);
    }
}
