<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Informações Reserva</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
	<div class="mb-3">
		<label for="horario" class="form-label">Horário selecionado: </label>
		<span id="horario">{{reservaForm.horario | number: '2.'}}:00</span>
	</div>
	<div class="mb-3">
		<label for="diaDaSemana" class="form-label">Dia da semana: </label>
		<span id="diaDaSemana">{{mapSemanaLabel(reservaForm.diaDaSemana)}}</span>
	</div>
	<div class="mb-3" *ngIf="isAdmin()">
		<label for="terapeutaNome" class="form-label">Selecione o terapeuta:</label>
		<select class="form-select" [(ngModel)]="reservaForm.terapeutaId">
			<option *ngFor="let terapeuta of terapeutas | sort:'nome'" [value]="terapeuta.id" [selected]="terapeuta.id == reservaForm?.terapeutaId">{{terapeuta.nome}}</option>
		</select>
	</div>
    <div class="mb-3">
		<label for="nomePaciente" class="form-label">Selecione o paciente:</label>
		<select class="form-select" [(ngModel)]="reservaForm.pacienteId">
			<option *ngFor="let paciente of pacientes | sort:'nome'" [value]="paciente.id" [selected]="paciente.id == reservaForm?.pacienteId">{{paciente.nome}}</option>
		</select>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" [disabled]="loading">Cancelar</button>
	<button type="button" class="btn btn-primary" (click)="solicitarReserva()" [disabled]="loading">Salvar</button>
</div>