import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DayOfWeek } from 'src/app/model/day-of-week.model';
import { Paciente } from 'src/app/model/paciente.model';
import { RestPacientesDataSource } from 'src/app/model/rest.pacientes.datasource';
import { RestSalasDataSource } from 'src/app/model/rest.salas.datasource';

import jwt_decode from "jwt-decode";
import { RestTerapeutasDataSource } from 'src/app/model/rest.terapeutas.datasource';
import { Terapeuta } from 'src/app/model/terapeuta.model';


@Component({
    templateUrl: './selecionar-paciente.component.html',
    styleUrls: ['./selecionar-paciente.component.scss'],
})
export class SelecionarPacienteComponent implements OnInit {
    public pacientes: Paciente[];
    public loading: boolean = false;
    public reservaForm: InformacoesParaReservaDto = new InformacoesParaReservaDto();
    public terapeutas: Terapeuta[];

    constructor(
        private pacientesRepository: RestPacientesDataSource,
        public modal: NgbActiveModal,
        private toastr: ToastrService,
        private salasRepository : RestSalasDataSource,
        private terapeutasRepository : RestTerapeutasDataSource,
        private authService: AuthenticationService) { }

    ngOnInit() {
        this.pacientesRepository.getListPacientesLightVersion().subscribe(
            data => this.pacientes = data,
            error => console.error(error)
        );

        this.terapeutasRepository.getListTerapeutas().subscribe(
            data => this.terapeutas = data,
            error => console.error(error)
        );
    }

    solicitarReserva() {
        if (!this.reservaForm.pacienteId) {
            this.toastr.error('Selecione um paciente');
            return;
        }

        this.salasRepository.reservarHorario(
            this.reservaForm.salaId,
            this.reservaForm.horario,
            this.reservaForm.diaDaSemana,
            this.reservaForm.pacienteId,
            this.isAdmin() ? this.reservaForm.terapeutaId : null).subscribe(
            () =>  { 
                this.toastr.success('Reserva efetuada com sucesso!');
                this.modal.close();
             },
            () => {
                this.toastr.error('Ocorreu um erro ao tentar reservar o horário');
            }
        );
    }

    isAdmin(): boolean {
        const user = jwt_decode<any>(JSON.stringify(this.authService.userValue.token));
        return user.TipoPerfil == 'Administrador';
    }

    mapSemanaLabel(diaAsEnum: DayOfWeek) {
        switch (diaAsEnum) {
            case DayOfWeek.Monday:
                return 'Segunda';
            case DayOfWeek.Tuesday:
                return 'Terça';
            case DayOfWeek.Wednesday:
                return 'Quarta';
            case DayOfWeek.Thursday:
                return 'Quinta';
            case DayOfWeek.Friday:
                return 'Sexta';
            case DayOfWeek.Saturday:
                return 'Sábado';
        }

        return 'NotMapped';
    }

}

class InformacoesParaReservaDto {
    pacienteId: string;
    terapeutaId: string | null;
    horario: number;
    diaDaSemana: DayOfWeek;
    salaId: string;
}