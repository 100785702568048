import { Component, OnInit } from '@angular/core';
import { RestSalasDataSource } from 'src/app/model/rest.salas.datasource';
import { Sala } from 'src/app/model/sala.model';

@Component({
    templateUrl: './listagem-salas.component.html',
    styleUrls: ['./listagem-salas.component.scss'],
})
export class ListagemSalasComponent implements OnInit {

    public salas: Sala[];
    public templateSelecionado: string;
    salaSelecionada: Sala;
    reservas: any;

    constructor(private salasRepository: RestSalasDataSource) { }

    ngOnInit() {
        this.salasRepository.getListSalas().subscribe(data => {
            this.salas = data;
        });
    }

    public selecionarTemplateSala(event: Event): void {
        const salaSelecionadaId = (<HTMLSelectElement>event?.target)?.value;
        const sala : Sala = this.salas.find(c=>c.salaId == salaSelecionadaId)!;

        this.templateSelecionado = sala.modalidade.toString();
        this.salaSelecionada = sala;

        this.salasRepository.getReservasSala(sala.salaId).subscribe(data => {
            this.reservas = data;
        });
    }
}