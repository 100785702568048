<div class="row clearfix m-3">
    <div class="offset-lg-1 col-lg-10 col-md-12">
        <div class="card all-patients paciente-card">
            <div class="body py-3">
                <div class="row">
                    <div class="col-lg-12">
                        
                        
                        <div class="widget-area-2 shadow-sm">
                            <h3 class="widget-title">Gerenciamento de salas</h3>
            
                            <div class="clearfix">
                                <select class="form-select" (change)="selecionarTemplateSala($event)">
                                    <option value="">--- Selecione uma sala ---</option>
                                    <option *ngFor="let sala of salas" [value]="sala.salaId">{{sala.nome}}</option>
                                </select>
                            </div>
            
                            <div class="mt-3">
                                <app-sala-semanal *ngIf="templateSelecionado === 'Recorrente'" [salaId]="salaSelecionada.salaId" [reservas]="reservas" [totalVagasSala]="salaSelecionada.limiteUsoSimultaneo"></app-sala-semanal>
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>

</div>