import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AtendimentoScheduler } from "./atendimento-scheduler.model";
import { Atendimento } from "./atendimento.model";
import { Terapeuta } from "./terapeuta.model";


@Injectable()
export class RestTerapeutasDataSource {

    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.ApiUrl
    }

    getAgenda(firstDay: Date, lastDay: Date): Observable<Atendimento[]> {
        return this.http.get<Atendimento[]>(this.baseUrl + `/terapeutas/agenda?inicio=${this.formatDate(firstDay)}&fim=${this.formatDate(lastDay)}`);
    }

    getListTerapeutas(): Observable<Terapeuta[]> {
        return this.http.get<Terapeuta[]>(this.baseUrl + `/terapeutas/light`);
    }

    getRelatorioPorTerapeuta(terapeutaId: string, dataInicio: Date, dataFinal: Date): Observable<ArrayBuffer> {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            responseType: 'arraybuffer' as 'json', // Define a resposta como um array de bytes
          };

        return this.http.get<ArrayBuffer>(this.baseUrl + `/terapeutas/${terapeutaId}/atendimentos/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}`, options);
    }

    getRelatorioTodosTerapeutas(dataInicio: Date, dataFinal: Date): Observable<ArrayBuffer> {
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            responseType: 'arraybuffer' as 'json', // Define a resposta como um array de bytes
          };
          
        return this.http.get<ArrayBuffer>(this.baseUrl + `/terapeutas/todos/atendimentos/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}`, options);
    }

    getAgendamentos(): Observable<{ [dayOfWeek: number]: AtendimentoScheduler[] }> {
        return this.http.get<{ [dayOfWeek: number]: AtendimentoScheduler[] }>(this.baseUrl + '/terapeutas/agendamentos');
    }

    deleteAgendamento(id: string): Observable<any> {
        return this.http.delete(this.baseUrl + `/terapeutas/agendamentos/${id}`);
    }

    criarAgendamento(agendamento: AtendimentoScheduler) {
        return this.http.post(this.baseUrl + `/terapeutas/agendamentos`, agendamento);
    }

    atualizarAgendamento(agendamento: AtendimentoScheduler) {
        return this.http.put(this.baseUrl + `/terapeutas/agendamentos`, agendamento);
    }

    private formatDate(data: Date): string {
        const offset = data.getTimezoneOffset()
        data = new Date(data.getTime() - (offset * 60 * 1000))
        return data.toISOString().split('T')[0]
    }
}
