import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
    selector: 'app-paciente-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    isUserLogged(): boolean {
        return this.authenticationService.userValue != null;
    }

    hasClaim(claimName: string): boolean {
        return this.authenticationService.userValue.claims.some(c => c == claimName);
    }

    logout(): void {
        this.authenticationService.logout();
    }

    ngOnInit(): void {
    }
}
