<div class="container">
    <div class="row d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="col-lg-7">
            <div class="login-form bg-light mt-4 p-4 shadow">
                <form ngNativeValidate #form="ngForm" (ngSubmit)="login(form)" class="row g-3">
                    <h4 class="text-center">Prontuario Eletrônico</h4>
                    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
                        {{errorMessage}}
                    </div>
                    <div class="col-12">
                        <label class="mb-2">E-mail</label>
                        <input type="email" name="email" class="form-control" [(ngModel)]="email" placeholder="Digite seu e-mail" required>
                    </div>
                    <div class="col-12">
                        <label class="mb-2">Senha</label>
                        <input type="password" name="password" class="form-control" [(ngModel)]="password" placeholder="************" required>
                    </div>
                    <div class="col-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="rememberMe" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" />
                            <label class="form-check-label" for="rememberMe"> Manter conectado</label>
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button [disabled]="loading" class="btn btn-primary" type="submit">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Login
                        </button>
                    </div>
                </form>
                <hr class="mt-4">
                <div class="col-12">
                    <p class="text-center mb-0">Have not account yet? <a href="#">Signup</a></p>
                </div>
            </div>
        </div>
    </div>
</div>