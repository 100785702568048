import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, Injector, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { CalendarOptions, DatesSetArg, EventApi, EventClickArg, EventMountArg } from '@fullcalendar/angular';
import PortugueseLocale from '@fullcalendar/core/locales/pt-br';
import { DateClickArg } from '@fullcalendar/interaction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PopoverWrapperComponent } from '../global/popover-wrapper/popover-wrapper-component';
import { StatusAtendimento, TipoEvento } from '../model/atendimento.model';
import { RestAtendimentosDataSource } from '../model/rest.atendimentos.datasource';
import { RestTerapeutasDataSource } from '../model/rest.terapeutas.datasource';
import { AddAtendimentoModalComponent } from './add-atendimento.component';


const BIRTHDAY = 0;

@Component({
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    @ViewChild('popoverTmpl', { static: true }) popoverTmpl: TemplateRef<any>;
    popoversMap = new Map<any, ComponentRef<PopoverWrapperComponent>>();
    popoverFactory = this.resolver.resolveComponentFactory(PopoverWrapperComponent);
    private fcDefaultView: string = localStorage.getItem("fcDefaultView") || 'timeGridWeek';
    private fcDefaultDate: string | Date = localStorage.getItem("fcDefaultDate") || new Date;
    statusList: any[];

    calendarOptions: CalendarOptions = {
        locale: PortugueseLocale,
        initialView: this.fcDefaultView,
        initialDate: this.fcDefaultDate,
        eventClick: this.showPopover.bind(this),
        dateClick: this.dateClickEventHandler.bind(this),
        datesSet: this.handleDateSet.bind(this),
        eventDidMount: this.createPopovers.bind(this),
        eventWillUnmount: this.destroyTooltip.bind(this),
        headerToolbar: {
            end: 'dayGridMonth,timeGridDay,timeGridWeek,listDay,listWeek today prev,next'
        },
        showNonCurrentDates: false,
        buttonText: {
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
            listDay: "Lista do dia",
            listWeek: "Lista da semana"
        },
        eventDisplay: 'block',
        height: '800px',
        slotMinTime: '07:00:00',
        slotMaxTime: '22:00:00',
        hiddenDays: [0]
    };

    constructor(
        private terapeutasRepository: RestTerapeutasDataSource,
        private atendimentosRepository: RestAtendimentosDataSource,
        private resolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
        private deviceService: DeviceDetectorService,
        private modalService: NgbModal) {

        if (this.deviceService.isMobile())
            this.calendarOptions = this.mobileCalendarOptions();

    }
    ngOnInit(): void {
        this.atendimentosRepository.getStatusList().subscribe(
            data => this.statusList = data,
            error => console.error(error)
        );
    }

    createPopovers(arg: EventMountArg) {
        if (arg.event._def.extendedProps.tipo === BIRTHDAY)
            return;

        if (arg.view.type === 'listWeek')
            return;

        const projectableNodes = Array.from(arg.el.childNodes);
        const compRef = this.popoverFactory.create(this.injector, [projectableNodes], arg.el);
        compRef.instance.template = this.popoverTmpl;
        compRef.instance.title = `Horário: ${arg.event.start?.toLocaleTimeString('pt-BR').substring(0, 5)} - ${arg.event.end?.toLocaleTimeString('pt-BR').substring(0, 5)}`;
        this.appRef.attachView(compRef.hostView);
        this.popoversMap.set(arg.el, compRef);

        localStorage.setItem("fcDefaultView", arg.view.type);
    }

    destroyTooltip(event: EventMountArg) {
        const popover = this.popoversMap.get(event.el);
        if (popover) {
            this.appRef.detachView(popover.hostView);
            popover.destroy();
            this.popoversMap.delete(event.el);
        }
    }

    showPopover(event: EventClickArg) {
        const popover = this.popoversMap.get(event.el);
        if (popover) {
            if (!popover.instance.popover.isOpen())
                popover.instance.popover.open({ event: event.event });
            else
                popover.instance.popover.close();
        }
    }

    dateClickEventHandler(event: DateClickArg) {
        const modalRef = this.modalService.open(AddAtendimentoModalComponent);
        modalRef.componentInstance.event = event;
        modalRef.componentInstance.statusList = this.statusList;
    }

    handleDateSet(event: DatesSetArg) {
        event.end.setDate(event.end.getDate() - 1);
        this.defineAgenda(event.start, event.end);

        localStorage.setItem("fcDefaultView", event.view.type);
        localStorage.setItem("fcDefaultDate", event.startStr);
    }

    changeEventStatus(event: EventApi, statusId: string): void {
        this.atendimentosRepository.changeStatus(event._def.extendedProps.pacienteId, statusId, event.start!, event.end!)
            .subscribe(
                () => window.location.reload(),
                () => { }
            );
    }

    removerAtendimento(pacienteId: string, atendimentoId: string): void {
        this.atendimentosRepository.remover(pacienteId, atendimentoId)
            .subscribe(
                () => window.location.reload(),
                () => { }
            );
    }

    private defineAgenda(firstDay: Date, lastDay: Date) {
        this.terapeutasRepository.getAgenda(firstDay, lastDay)
            .subscribe(
                events => {
                    for (let i = 0; i < events.length; i++) {
                        const event = events[i];

                        if (event.extendedProps.tipo === TipoEvento.Birthday)
                            event.color = 'rgb(96, 92, 168)';
                        else if (event.extendedProps.tipo === TipoEvento.Event) {
                            const statusAtendimento = new StatusAtendimento(event.extendedProps.status);
                            event.color = statusAtendimento.getColor();
                        }
                    }
                    this.calendarOptions.events = events;
                },
                error => { console.error(error) }
            );
    }

    private mobileCalendarOptions(): CalendarOptions {
        return {
            locale: PortugueseLocale,
            initialView: 'timeGridDay',
            initialDate: this.fcDefaultDate,
            eventClick: this.showPopover.bind(this),
            datesSet: this.handleDateSet.bind(this),
            eventDidMount: this.createPopovers.bind(this),
            eventWillUnmount: this.destroyTooltip.bind(this),
            headerToolbar: {
                end: 'today prev,next'
            },
            showNonCurrentDates: false,
            buttonText: {
                today: "Hoje"
            },
            eventDisplay: 'block',
            height: '700px',
            slotMinTime: '07:00:00',
            slotMaxTime: '22:00:00',
            views: {
                timeGridDay: {
                    dayHeaderFormat: { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', omitCommas: true }
                }
            }
        }
    }
}