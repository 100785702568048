export class Atendimento {
    color: string;
    extendedProps: ExtendedProps;
}

export enum TipoEvento {
    Birthday,
    Event
}

interface StatusAtendimentoContract {
    id: string;
    color: string;
    description: string;
}

export class StatusAtendimento {    
    id: string;
    color: string;
    description: string;

    constructor(contract: StatusAtendimentoContract) {
        this.color = contract.color,
        this.id = contract.id,
        this.description = contract.description;    
    }

    public getColor(): string {
        return `#${this.color}`;
    }
}

export class ExtendedProps {
    status: StatusAtendimento;
    tipo: TipoEvento;
}