import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import { environment } from 'src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private spinner: NgxSpinnerService, private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        this.spinner.show();

        const user = this.authenticationService.userValue;
        const isLoggedIn = user && user.token;
        const isApiUrl = request.url.startsWith(environment.ApiUrl);

        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.token}`
                }
            });
        }

        return next.handle(request).pipe(
            catchError((httpErrorResponse: HttpErrorResponse, req: Observable<HttpEvent<any>>) => {

                if (httpErrorResponse.status === HttpStatusCode.Unauthorized || httpErrorResponse.status === 0) {
                    this.authenticationService.logout();
                    this.authenticationService.redirectToLogin("login", { queryParams: { returnUrl: request.url } });
                }

                return throwError(httpErrorResponse);

            }),
            finalize(() => {
                this.spinner.hide();
            }));
    }
}