<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Adicionar atendimento manual</h4>
	<button type="button" class="btn-close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
	<div class="mb-3">
		<label for="nomePaciente" class="form-label">Selecione o paciente:</label>
		<select class="form-select" [(ngModel)]="addAtendimentoForm.pacienteId">
			<option *ngFor="let paciente of pacientes | sort:'nome'" [value]="paciente.id">{{paciente.nome}}</option>
		</select>
	</div>
	<div class="mb-3">
		<label for="horarioAtendimentoInicial" class="form-label">Horário Início:</label>
		<input type="time" class="form-control" name="horarioAtendimento" id="horarioAtendimento" [(ngModel)]="addAtendimentoForm.initTime" required />
	</div>
	<div class="mb-3">
		<label for="horarioAtendimentoFinal" class="form-label">Horário Final:</label>
		<input type="time" class="form-control" name="horarioAtendimentoFinal" id="horarioAtendimentoFinal" [(ngModel)]="addAtendimentoForm.endTime" required />
	</div>
	<div class="mb-3">
		<label for="statusId" class="form-label">Status:</label>
		<select name="statusId" id="statusId" class="form-select" [(ngModel)]="addAtendimentoForm.statusId">
			<option *ngFor="let status of statusList | sort:'description'" [value]="status.id">{{status.description}}</option>
		</select>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')" [disabled]="loading">Cancelar</button>
	<button type="button" class="btn btn-primary" (click)="salvarAgendamento()" [disabled]="loading">Salvar</button>
</div>