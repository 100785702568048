import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DirectivesModule } from '../_directives/directives.module';
import { ListagemSalasComponent } from './listagem-salas/listagem-salas.component';
import { SalasRoutingModule } from './salas-routing.module';
import { AddHorarioComponent } from './add-horario/add-horario.component';
import { SalaSemanalComponent } from './sala-semanal/sala-semanal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelecionarPacienteComponent } from './selecionar-paciente/selecionar-paciente.component';

@NgModule({
	declarations: [
		ListagemSalasComponent,
		AddHorarioComponent,
		SalaSemanalComponent,
		SelecionarPacienteComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		DirectivesModule,
		SalasRoutingModule,
		NgbModule
	],
	exports: [
	]
})
export class SalasModule { }
