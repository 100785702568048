import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { ModalComponent } from './modal/modal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PopoverWrapperComponent } from './popover-wrapper/popover-wrapper-component';


@NgModule({
  declarations: [
    NavbarComponent,
    PopoverWrapperComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule
  ],
  exports: [
    NavbarComponent,
    ModalComponent
  ]
})
export class GlobalModule { }
