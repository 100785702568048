import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";



@Injectable()
export class RestAtendimentosDataSource {
    private baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.ApiUrl
    }

    changeStatus(pacienteId: string, statusId: string, dataInicio: Date, dataFinal: Date): Observable<any> {
        return this.http.patch(this.baseUrl + `/pacientes/${pacienteId}/atendimentos`, { statusId, dataInicio, dataFinal });
    }

    remover(pacienteId: string, atendimentoId: string): Observable<any> {
        return this.http.delete(this.baseUrl + `/pacientes/${pacienteId}/atendimentos/${atendimentoId}`);
    }

    getRelatorioTodosAtendimentos(dataInicio: Date, dataFinal: Date, terapeutaId: null | string): Observable<ArrayBuffer> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'arraybuffer' as 'json',
        };

        if (terapeutaId != null)
            return this.http.get<ArrayBuffer>(this.baseUrl + `/atendimentos/todos/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}&terapeutaId=${terapeutaId}`, options);

        return this.http.get<ArrayBuffer>(this.baseUrl + `/atendimentos/todos/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}`, options);
    }

    getRelatorioAgrupados(dataInicio: Date, dataFinal: Date, terapeutaId: null | string): Observable<ArrayBuffer> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            responseType: 'arraybuffer' as 'json',
        };

        if (terapeutaId == null)
            return this.http.get<ArrayBuffer>(this.baseUrl + `/atendimentos/agrupados/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}`, options);

        return this.http.get<ArrayBuffer>(this.baseUrl + `/atendimentos/agrupados/download?dataInicio=${dataInicio}&dataFinal=${dataFinal}&terapeutaId=${terapeutaId}`, options);
    }

    //ARRUMAR TIPAGEM
    getStatusList(): Observable<any> {
        return this.http.get(this.baseUrl + `/atendimentos/status`);
    }

    adicionarAtendimentoManual(informacoesAtendimento: any): Observable<any> {
        return this.http.post(this.baseUrl + `/atendimentos/manual`, informacoesAtendimento);
    }
}
