import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModelModule } from '../model/model.module';
import { DirectivesModule } from '../_directives/directives.module';
import { AddAtendimentoModalComponent } from './add-atendimento.component';
import { HomeComponent } from './home.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FullCalendarModule,
        ModelModule,
        DirectivesModule,
        NgbModule
    ],
    exports: [],
    declarations: [
        HomeComponent,
        AddAtendimentoModalComponent
    ],
    providers: [],
})
export class HomeModule { }
