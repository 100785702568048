import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Usuario } from '../model/usuario.model';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { TipoPerfil } from '../model/tipoPerfil.model';

const EXPIRATION_IN_MS = 900000;

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private userSubject: BehaviorSubject<Usuario>;
    public user: Observable<Usuario>;

    constructor(private router: Router, private http: HttpClient) {
        this.userSubject = new BehaviorSubject<Usuario>(this.getWithExpiry('user'));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): Usuario {
        return this.userSubject.value;
    }

    login(email: string, password: string, rememberMe: boolean) {
        return this.http.post<Usuario>(`${environment.ApiUrl}/usuarios/auth`, { email, password, rememberMe })
            .pipe(map(user => {
                const tokenDecoded = jwt_decode<any>(JSON.stringify(user.token));
                user.tipoPerfil = tokenDecoded.TipoPerfil == 'Administrador' ? TipoPerfil.Administrador : TipoPerfil.Usuario;
                user.claims = tokenDecoded.Acessos?.split('|');
                this.userSubject.next(user);

                if (rememberMe)
                    localStorage.setItem('user', JSON.stringify({ value: user }));
                else
                    this.setWithExpiry('user', user, EXPIRATION_IN_MS);

                return user;
            }));
    }

    public redirectToLogin(path: string, queryParams: any) {
        this.router.navigate([path], {
            queryParams,
            queryParamsHandling: "merge",
        });
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('fcDefaultDate');
        localStorage.removeItem('fcDefaultView');
        this.userSubject.next(null!);
        this.router.navigate(['/login']);
    }

    private setWithExpiry(key: string, value: any, ttl: number) {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };

        localStorage.setItem(key, JSON.stringify(item));
    }

    private getWithExpiry(key: string) {
        const itemStr = localStorage.getItem(key);

        if (!itemStr) {
            return null;
        }

        const item = JSON.parse(itemStr);
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }

        return item.value;
    }
}